<template>
  <div class="container"  :style="{ backgroundImage: `url(${img.home.content.hemoBg})` }">
    <div class="container-left">
      <div class="es-screen-left-item left-top">
        <div class="titlePublic">
          <div class="column"></div>
          <div class="titleBox">
            <i class="triangle"></i>
            <span>{{ showLang('cdm.data.ambient.info') }}</span>
          </div>
        </div>
        <div class="left-top-cloud">
          <div class="cloud-left">
            <img :src="weather.url" class="cloud-left-img-cloud" />
            <div class="cloud-temp">
              <span>{{ weather.degree }}℃</span>
              <div>{{ weather.type }}</div>
            </div>
            <img :src="img.lightMainLocation" class="cloud-left-img-location" />
            <div class="cloud-location">
              <span>{{ showLang('cmd.data.area') }}:</span>
              <div>{{ environmentInfo.area }}</div>
            </div>
          </div>
          <div class="cloud-content">
            <div class="open-time">
              <img :src="img.lightMainSunRise" />
              <span class="time-title">{{ showLang('com.date.sunrise') }}</span>
              <span class="time-value">{{ environmentInfo.sunRise }}</span>
            </div>
            <div class="open-time">
              <img :src="img.lightMainSunDown" />
              <span class="time-title">{{ showLang('com.date.sunset') }}</span>
              <span class="time-value">{{ environmentInfo.sunSet }}</span>
            </div>
            <div class="open-time">
              <img :src="img.lightMainOpenTime" style="margin-right: 12px" />
              <span class="time-title">{{ showLang('com.date.lamp') }}</span>
              <span class="time-value">{{ environmentInfo.open }}</span>
            </div>
            <div class="open-time">
              <img :src="img.lightMainCloseTime" style="margin-right: 9px" />
              <span class="time-title">{{ showLang('com.date.turn') }}</span>
              <span class="time-value">{{ environmentInfo.close }}</span>
            </div>
          </div>
        </div>
        <div class="left-top-rotation">
          <template>
            <Carousel autoplay dots="none" :autoplay-speed="10000" v-model="value1">
              <Carousel-item v-for="(el, index) in environmentInfo.luxes" v-bind:key="index">
                <div class="rotation-title"><img :src="img.home.page.location" />{{ el.stationName }}</div>
                <div class="left-top-status">
                  <div class="status-item">
                    <span class="status-item-title">{{ showLang('cmd.lux.val') }}</span>
                    <span class="status-item-value">{{ el.lux }} lux</span>
                  </div>
                  <div class="rotation-column"></div>
                  <div class="status-item">
                    <span class="status-item-title">{{ showLang('com.but.open.lamp.limit') }}</span>
                    <span class="status-item-value">{{ el.open }} lux</span>
                  </div>
                  <div class="rotation-column"></div>
                  <div class="status-item">
                    <span class="status-item-title">{{ showLang('com.date.delay') }}</span>
                    <span class="status-item-value">{{ el.close }} min</span>
                  </div>
                  <div class="rotation-column"></div>
                  <div class="status-item">
                    <span class="status-item-title">{{ showLang('com.lighting.linkage.status') }}</span>
                    <span class="status-item-value">{{ el.enable ? showLang('com.op.open') : showLang('com.op.shut') }}</span>
                  </div>
                </div>
              </Carousel-item>
            </Carousel>
          </template>
        </div>
      </div>
      <div class="es-screen-left-item left-middle">
        <div class="titlePublic">
          <div class="column"></div>
          <div class="titleBox">
            <i class="triangle"></i>
            <span>{{ showLang('com.data.light.statis') }}</span>
          </div>
        </div>
        <div class="power-box-count">
          <div class="power-box-count-desc">
            <img :src="img.lightMainPoint" />
            <span>{{ showLang('com.data.ggd.total') }}</span>
            <div v-for="n in 9" class="number-block" :key="n" :style="{ backgroundImage: `url(${img.home.page.numberBg})` }">
              {{ `${equipmentInfo.powerbox.total}`[n - 1] }}
            </div>
          </div>
        </div>
        <div class="power-box-detail">
          <div class="power-detail-item">
            <img :src="img.home.page.policeNormal" />
            <div>
              <span class="grey">{{ showLang('com.state.onLine.num') }}</span>
              <span class="no-opacity"
                >{{ equipmentInfo.powerbox.online }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
              >
            </div>
          </div>
          <div class="power-detail-item">
            <img :src="img.home.page.policeLine" />
            <div>
              <span class="grey">{{ showLang('com.state.offline.num') }}</span>
              <span class="no-opacity"
                >{{ equipmentInfo.powerbox.total - equipmentInfo.powerbox.online }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
              >
            </div>
          </div>
        </div>
        <div class="power-box-detail">
          <div class="power-detail-item">
            <img :src="img.home.page.policeNum" />
            <div>
              <span class="grey">{{ showLang('com.state.alarm.num') }}</span>
              <span class="no-opacity"
                >{{ equipmentInfo.powerbox.alarm }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
              >
            </div>
          </div>
          <div class="power-detail-item">
            <img :src="img.home.page.policeRepair" />
            <div>
              <span class="grey">{{ showLang('com.state.repair.num') }}</span>
              <span class="no-opacity"
                >{{ equipmentInfo.powerbox.repair }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
              >
            </div>
          </div>
        </div>
        <div class="light-box-count">
          <div class="power-box-count-desc">
            <img :src="img.lightMainPoint" />
            <span style="margin-right: 23px">{{ showLang('com.light.total') }}</span>
            <div v-for="n in 9" class="number-block" :key="n" :style="{ backgroundImage: `url(${img.home.page.numberBg})` }">
              {{ `${equipmentInfo.lamp.total}`[n - 1] }}
            </div>
          </div>
        </div>
        <div class="power-box-detail">
          <div class="power-detail-item">
            <img :src="img.home.page.policeNormal" />
            <div>
              <span class="grey">{{ showLang('com.state.onLine.num') }}</span>
              <span class="no-opacity"
                >{{ equipmentInfo.lamp.online }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
              >
            </div>
          </div>
          <div class="power-detail-item">
            <img :src="img.home.page.lightNum" />
            <div>
              <span class="grey">{{ showLang('com.state.lighting.num') }}</span>
              <span class="no-opacity"
                >{{ equipmentInfo.lamp.running }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
              >
            </div>
          </div>
        </div>
        <div class="power-box-detail">
          <div class="power-detail-item">
            <img :src="img.home.page.policeNum" />
            <div>
              <span class="grey">{{ showLang('com.state.alarm.num') }}</span>
              <span class="no-opacity"
                >{{ equipmentInfo.lamp.alarm }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="es-screen-left-item left-bottom">
        <div class="titlePublic">
          <div class="column"></div>
          <div class="titleBox">
            <i class="triangle"></i>
            <span>{{ showLang('com.stat.light.rate') }}</span>
          </div>
        </div>
        <div class="left-gauge-area">
          <chartsLine :option="chartsLineData" id="chartsLineData" :key="timer1"></chartsLine>
        </div>
      </div>
    </div>
    <div class="container-center">
      <div class="center-top">
        <div class="center-top-item">
          <img :src="img.home.page.assetsBox" />
          <div>
            <span class="count-title">{{ showLang('com.device.box') }}</span
            ><span class="count-value"
              >{{ assetInfo.power }} <span class="count-title">{{ showLang('com.unit.pcs') }}</span></span
            >
          </div>
        </div>
        <!-- <div class="center-top-item">
          <img :src="img.home.page.assetsCable" />
          <div>
            <span class="count-title">{{ showLang('com.device.cable') }}</span
            ><span class="count-value">{{ assetInfo.line }} <span class="count-title">M</span></span>
          </div>
        </div>
        <div class="center-top-item">
          <img :src="img.home.page.assetsLampPole" />
          <div>
            <span class="count-title">{{ showLang('com.device.pole') }}</span
            ><span class="count-value"
              >{{ assetInfo.pole }} <span class="count-title">{{ showLang('com.unit.branch') }}</span></span
            >
          </div>
        </div> -->
        <div class="center-top-item">
          <img :src="img.home.page.assetsLamps" />
          <div>
            <span class="count-title">{{ showLang('com.device.light') }}</span
            ><span class="count-value"
              >{{ assetInfo.light }} <span class="count-title">{{ showLang('com.unit.only') }}</span></span
            >
          </div>
        </div>
      </div>
      <!-- <div class="center-middle" :style="{ backgroundImage: `url(${img.lightMainLightBeijing})` }"> -->
      <div class="center-middle">
        <!-- <template v-for="item in mapImgList">
          <img :src="item.img" alt="" :key="item.id" />
        </template> -->
        <homeMap />
      </div>
      <div class="es-screen-bottom-item center-bottom">
        <div class="titlePublic">
          <div class="column"></div>
          <div class="titleBox">
            <i class="triangle"></i>
            <span>{{ showLang('com.stat.energy.analysis') }}</span>
          </div>
        </div>
        <div class="energy-content">
          <chartsLine :option="energyLineData" id="energyLineData" :key="timer2"></chartsLine>
        </div>
      </div>
    </div>
    <div class="container-right">
      <div class="es-screen-right-item right-top">
        <div class="titlePublic">
          <div class="column"></div>
          <div class="titleBox">
            <i class="triangle"></i>
            <span>{{ showLang('com.order.processing') }}</span>
          </div>
        </div>
        <div class="chart-gauge-area">
          <div class="work-chart">
            <div class="light-list-title"><img :src="img.lightMainPoint" />{{ showLang('com.order.service') }}</div>
            <chartsPie :option="chartsPieData1" id="leftMaintain" :key="timer3" style="height: 160px"></chartsPie>
            <div class="typeList" style="margin-top: 46px">
              <div class="type"><i class="blue1"></i>{{ showLang('com.order.escalation') }}</div>
              <div class="type"><i class="green"></i>{{ showLang('com.order.daily') }}</div>
              <div class="type"><i class="blue2"></i>{{ showLang('com.order.system') }}</div>
            </div>
          </div>
          <div class="work-chart">
            <div class="light-list-title"><img :src="img.lightMainPoint" />{{ showLang('com.system.order') }}</div>
            <chartsPie :option="chartsPieData2" id="leftMaintain2" :key="timer4" style="height: 170px"></chartsPie>
            <div class="typeRight" style="margin-top: 10px">
              <div class="type"><i class="sys2"></i>{{ showLang('com.order.processed') }}</div>
              <div class="type"><i class="sys4"></i>{{ showLang('com.order.received.alarm') }}</div>
            </div>
            <div class="typeRight" style="margin-top: 10px">
              <div class="type"><i class="sys3"></i>{{ showLang('com.order.fault.diagnosis') }}</div>
              <div class="type"><i class="sys1"></i>{{ showLang('com.order.confirmed') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class=" es-screen-right-item right-bottom">
        <div class="titlePublic">
          <div class="column"></div>
          <div class="titleBox">
            <i class="triangle"></i>
            <span>{{ showLang('com.data.statistics.alarm') }}</span>
          </div>
        </div>
        <div class="light-list-title"><img :src="img.lightMainPoint" />{{ showLang('com.lighting.ggd.alarm') }}</div>
        <div class="power-box-alarm">
          <div class="power-box-left">
            <div class="power-box-item">
              <img :src="img.lightMainAlarmFetal" />
              <div>
                <span class="grey">{{analysisBox[0] && analysisBox[0].key}}</span>
                <span class="no-opacity">{{ analysisBox[0] && analysisBox[0].value }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
                >
              </div>
            </div>
            <div class="power-box-item">
              <img :src="img.lightMainAlarmCommon" />
              <div>
                <span class="grey">{{analysisBox[1] && analysisBox[1].key}}</span>
                <span class="no-opacity">{{analysisBox[1] &&  analysisBox[1].value }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
                >
              </div>
            </div>
            <div class="power-box-item">
              <img :src="img.lightMainAlarmPre" />
              <div>
                <span class="grey">{{analysisBox[2] && analysisBox[2].key}}</span>
                <span class="no-opacity">{{ analysisBox[2] && analysisBox[2].value }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
                >
              </div>
            </div>
          </div>
          <div class="power-box-right">
            <div class="chart-type-left">
              <div class="chart-type-list" v-for="(el, index) in chartsPieData3.series.slice(0, 3)" :key="index">
                <span class="chart-type"
                  >{{ el.name }} <span class="chart-num">{{ el.value }}</span>
                  <i
                    class="chart-type-color"
                    :style="{
                      backgroundColor: `${chartsPieData3.color[index]}`
                    }"
                  ></i
                ></span>
              </div>
            </div>
            <div class="chart-type-chart">
              <div class="chart-chart">
                <chartsPie :option="chartsPieData3" id="leftMaintain3" :key="timer5" style="height: 178px;width:178px;margin: 0 auto;"> </chartsPie>
              </div>
              <div
                class="chart-title"
                :style="{
                  backgroundImage: `url(${img.home.page.policeBoxBg})`
                }"
              >
                <img :src="img.home.page.policeBox" />
                <span class="name">{{ showLang('com.box.alarm') }}</span>
                <span class="num">{{ analysisBoxTotal }}</span>
              </div>
            </div>
            <div class="chart-type-left ">
              <div class="chart-type-list con-right " v-for="(el, index) in chartsPieData3.series.slice(3, 6)" :key="index">
                <span class="chart-type"
                  ><i class="chart-type-color" :style="{ backgroundColor: `${chartsPieData3.color[index + 3]}` }"> </i><span class="chart-num"> {{ el.value }}</span> {{ el.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="light-list-title" style="padding-top:0"><img :src="img.lightMainPoint" />{{ showLang('com.lamp.alarm') }}</div>
        <div class="power-box-alarm">
          <div class="power-box-left">
            <div class="power-box-item">
              <img :src="img.lightMainAlarmFetal" />
              <div>
                <span class="grey">{{ analysisLamp[0] &&  analysisLamp[0].key }}</span>
                <span class="no-opacity" >{{analysisLamp[0] &&  analysisLamp[0].value }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
                >
              </div>
            </div>
            <div class="power-box-item">
              <img :src="img.lightMainAlarmCommon" />
              <div>
                <span class="grey">{{ analysisLamp[1] && analysisLamp[1].key }}</span>
                <span class="no-opacity" >{{analysisLamp[1] &&  analysisLamp[1].value }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
                >
              </div>
            </div>
            <div class="power-box-item">
              <img :src="img.lightMainAlarmPre" />
              <div>
                <span class="grey">{{ analysisLamp[2] && analysisLamp[2].key }}</span>
                <span class="no-opacity" >{{analysisLamp[2] &&  analysisLamp[2].value }}<i class="grey">{{ showLang('com.unit.pcs') }}</i></span
                >
              </div>
            </div>
          </div>
          <div class="power-box-right">
            <div class="chart-type-left">
              <div class="chart-type-list" v-for="(el, index) in chartsPieData4.series.slice(0, 3)" :key="index">
                <span class="chart-type"
                  >{{ el.name }} <span class="chart-num">{{ el.value }}</span>
                  <i
                    class="chart-type-color"
                    :style="{
                      backgroundColor: `${chartsPieData4.color[index]}`
                    }"
                  ></i
                ></span>
              </div>
            </div>
            <div class="chart-type-chart">
              <div class="chart-chart">
                <chartsPie :option="chartsPieData4" id="leftMaintain4" :key="timer6" style="height: 178px;width:178px;margin: 0 auto;"> </chartsPie>
              </div>
              <div
                class="chart-title"
                :style="{
                  backgroundImage: `url(${img.home.page.policeLampBg})`
                }"
              >
                <img :src="img.home.page.policeLamp" style="width:25px;height:32px" />
                <span class="name">{{ showLang('com.lamp.alarm') }}</span>
                <span class="num">{{ analysisLampTotal }}</span>
              </div>
            </div>
            <div class="chart-type-left ">
              <div class="chart-type-list con-right " v-for="(el, index) in chartsPieData4.series.slice(3, 6)" :key="index">
                <span class="chart-type"
                  ><i
                    class="chart-type-color"
                    :style="{
                      backgroundColor: `${chartsPieData4.color[index + 3]}`
                    }"
                  ></i>
                  <span class="chart-num">{{ el.value }}</span> {{ el.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { weatherData } from '@/plugins/weather'
import homeMap from './gis/homeMap/demo'
export default {
  name: 'Home',
  components: {
    homeMap
  },
  data() {
    return {
      analysisBox: [
        {
          level: 30,
          cnt: 0
        },
        {
          level: 20,
          cnt: 0
        },
        {
          level: 10,
          cnt: 0
        }
      ],
      analysisLamp: [
        {
          level: 30,
          cnt: 0
        },
        {
          level: 20,
          cnt: 0
        },
        {
          level: 10,
          cnt: 0
        }
      ],
      analysisBoxTotal: 0, //配电箱报警总数
      analysisLampTotal: 0, //单灯报警总数
      chartsLineData: {
        title: '',
        isAnimation:true,
        x: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun5'],
        unit: '%',
        yBorderColor: 'rgba(34, 51, 83, 0.45)',
        series: [
          {
            name: '亮灯率',
            data: []
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {} //下阴影
        }
      },
      energyLineData: {
        color: ['#02CB9E', '#A468FE', '#FC58B1', '#5A78FC', '#49B0FD'],
        title: '',
        isAnimation:true,
        xColor: '#B5C1CC',
        yColor: '#99A8B6',
        yBorderColor: 'rgba(34, 51, 83, 0.45)',
        x: [],
        unit: '',
        series: [
          {
            name: '当日能耗(KWh)',
            data: []
          },
          {
            name: '节电率(%)',
            data: []
          },
          {
            name: '平均额定功率(KW)',
            data: []
          },
          {
            name: '当日费用(元)',
            data: []
          },
          {
            name: '节省电费(元)',
            data: []
          }
        ],
        setSeries: {
          smooth: true //平滑折线图
        }
      },
      chartsPieData1: {
        color: ['#4177FF', '#04E0F9', '#0041FB'],
        isAnimation:true,
        series: [
          {
            value: 40,
            name: '40',
            itemStyle: { color: '#4177FF' },
            label: { color: '#4177FF' }
          },
          {
            value: 30,
            name: '30',
            itemStyle: { color: '#04E0F9' },
            label: { color: '#04E0F9' }
          },
          {
            value: 20,
            name: '20',
            itemStyle: { color: '#0041FB' },
            label: { color: '#0041FB' }
          }
        ],
        setSeries: {
          type: 'pie',
          radius: [35, 50],
          center: ['50%', '50%'],
          roseType: 'area'
        }
      },
      chartsPieData2: {
        color: ['#009CFF', '#3DF3F5', '#F8A754', '#FD7371'],
        isAnimation:true,
        series: [
          {
            value: 40,
            name: '40',
            itemStyle: { color: '#009CFF' },
            label: {
              color: '#009CFF',
              position: 'outer',
              alignTo: 'none',
              bleedMargin: 5
            }
          },
          {
            value: 38,
            name: '38',
            itemStyle: { color: '#3DF3F5' },
            label: {
              color: '#3DF3F5',
              position: 'outer',
              alignTo: 'none',
              bleedMargin: 5
            }
          },
          {
            value: 32,
            name: '32',
            itemStyle: { color: '#F8A754' },
            label: {
              color: '#F8A754',
              position: 'outer',
              alignTo: 'none',
              bleedMargin: 5
            }
          },
          {
            value: 30,
            name: '30',
            itemStyle: { color: '#FD7371' },
            label: {
              color: '#FD7371',
              position: 'outer',
              alignTo: 'none',
              bleedMargin: 5
            }
          }
        ],
        tooltip: {
          trigger: 'item',
          confine: true //将此限制打开后tooltip将不再溢出
        },
        setSeries: {
          roseType: '',
          itemStyle: {
            borderColor: '#010B16',
            borderWidth: 3
          }
        }
      },
      chartsPieData3: {
        color: ['#0CB2FF', '#1186FF', '#0354CE', '#142BD4', '#3A3AF6', '#6244F8', '#8844F8', '#36C688'],
        series: [
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' }
        ],
        setSeries: {
          label: {
            show: false,
            position: 'center'
          },
          radius: ['75%', '90%'],
          itemStyle: {
            borderColor: '#010B16',
            borderWidth: 4
          }
        }
      },
      chartsPieData4: {
        color: ['#3FB8D6', '#2690FD', '#DAC127', '#E0395F', '#C156E5', '#36C688'],
        series: [
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' },
          { value: 0, name: '' }
        ],
        setSeries: {
          label: {
            show: false,
            position: 'center'
          },
          radius: ['75%', '90%'],
          itemStyle: {
            borderColor: '#010B16',
            borderWidth: 4
          }
        }
      },
      environmentInfo: {
        area: '北京',
        close: '05:31',
        lat: 39.910924,
        lng: 116.413387,
        luxes: [],
        open: '18:59',
        sunRise: '',
        sunSet: ''
      },
      equipmentInfo: {
        "count": 468,
        "line": 7358.9,
        "powerbox": {
            "total": 0,
            "online": 0,
            "alarm": 0,
            "running": 0,
            "repair": 0,
            "stop": 0
        },
        "light": {
            "total": 0,
            "online": 0,
            "alarm": 0,
            "running": 0,
            "repair": 0,
            "stop": 0
        },
        "pole": {
            "total": 0,
            "online": 0,
            "alarm": 0,
            "running": 0,
            "repair": 0,
            "stop": 0
        },
        "lamp": {
            "total": 0,
            "online": 0,
            "alarm": 0,
            "running": 0,
            "repair": 0,
            "stop": 0
        }
      },
      assetInfo: {
        light: 0,
        line: 0,
        pole: 0,
        power: 0
      },
      workOrder: {
        types: {
          upload: 0,
          day: 0,
          sys: 0
        },
        sys: {
          got: 0,
          approve: 0,
          level: 0,
          deal: 0
        }
      },
      lightingRateInfo: [{ time: '2022-04-02 11:36:09', value: 0 }],
      value1: 0,
      weeks: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      options: {
        center: { lng: 113.828333, lat: 22.770866 }
      },
      ticker: null,
      nowTime: {
        date: '',
        week: '',
        time: '',
        sunRise: '',
        sunSet: ''
      },
      timer: 0,
      timer1: 0,
      timer2: 0,
      timer3: 0,
      timer4: 0,
      timer5: 0,
      timer6: 0,
      policeTypeData: {},
      weather: {
        icon: '',
        degree: '',
        type: '',
        url: weatherData[0].url
      },
      mapImgList: [],
      timeout: null
    }
  },
  computed: {
    ...mapState('group', ['selectedDevices', 'selectedNode', 'groups']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['token', 'user', 'customerId', 'appTypes', 'config', 'agentChangeValue'])
  },
  watch: {
    agentChangeValue() {
      this.debounceInitData()
    },
    windowOnResize() {
      // this.widthChange()
    }
  },
  mounted: function() {
    this.$Notice.config({
      top: 220,
      duration: 10
    })
    this.ticker = setInterval(this.refreshTime, 1000)
    this.debounceInitData()
    this.chartsLineData.series[0].name = this.showLang('com.stat.light.rate')
    this.energyLineData.series[0].name = this.showLang('com.stat.day.energy')
    this.energyLineData.series[1].name = this.showLang('com.stat.saving.rate')
    this.energyLineData.series[2].name = this.showLang('energy.before.energyPower') + '(KW)'
    this.energyLineData.series[3].name = this.showLang('com.stat.day.fee')
    this.energyLineData.series[4].name = this.showLang('com.stat.save.charge')
  },
  destroyed: function() {
    if (this.ticker) {
      clearInterval(this.ticker)
      this.ticker = null
    }
  },
  methods: {
    toggleFullScreen() {
     if (!document.fullscreenElement &&   !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) { 
        this.enterFullScreen();
      } else {
        this.exitFullScreen();
      }
    },
    enterFullScreen() {
      let element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
      }
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    },
    testEvent: function() {
      // console.log('ready emit home load event', this);
      let params = { time: new Date().format('yyyy-MM-dd HH:mm:ss.fff') }
      window.eventBus.$emit('homeLoaded', params)
      // this.$emit('homeLoaded', params);
      console.log('emit home load event', params)
    },
    debounceInitData: function() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.initData, 500)
    },
    initData: function() {
      this.options.center.lat = this.config.lat
      this.options.center.lng = this.config.lng
      this.mapImg() //地图信息
      this.environment() //环境信息
      this.equipment() //智能照明设备统计信息

      this.getWeather() //获取天气
      this.lightingRate() //亮灯率
      this.asset() //资产
      this.getWorkOrderCount() //工单处理情况
      this.energy() //工单处理情况
      this.policeType() //工单处理情况
      this.toggleFullScreen();//全屏
    },
    mapImg() {
      this.$axios.post(`//${this.domains.trans}/sys/auth/QueryGroupMap`, {}).then(res => {
        if (res.code == 0) {
          res.data.forEach(el => {
            el.img = `//${this.domains.trans}/group/map/${el.img}?auth=${this.token}`
          })
          this.mapImgList = res.data
          if (res.data.length == 0) {
            this.mapImgList.push({ img: this.img.bigMap.defaultMap, id: 0 })
          }
        }
      })
    },
    policeType: async function() {
      let res = await this.$axios.post(`//${this.domains.trans}/station/alarm/QueryStationTypesOld`, {})
      if (res.code !== 0) {
        return
      }
      this.policeTypeData = res.data
      this.policeAjax()
    },
    policeAjax: async function() {
      let res = await this.$axios.post(`//${this.domains.trans}/station/alarm/AnalyseHome`,{})
      if (res.code !== 0) {
        return
      }
      this.analysisBoxTotal =res.data.power.count
      this.analysisLampTotal = res.data.light.count
      this.analysisBox =res.data.power.levels
      this.analysisLamp =res.data.light.levels
      this.chartsPieData3.series = res.data.power.types.length > 0 ? this.typeAdd(res.data.power.types) : this.chartsPieData3.series
      this.chartsPieData4.series = res.data.light.types.length > 0 ? this.typeAdd(res.data.light.types) : this.chartsPieData4.series
      this.timer6++
      this.timer5++
    },
    typeAdd(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].cnt= data[i].value 
        data[i].name =  data[i].key
      }
      if (data.length > 6) {
        data[5].name = this.showLang('com.op.other')
        data.forEach((el, index) => {
          if (index > 5) {
            data[5].cnt = el.value * 1 + data[5].value * 1
          }
        })
      }
      return data.slice(0, 6)
    },
    energy: async function() {
      let now = new Date()
      let end = now.format('yyyy-MM-dd')
      now.setDate(now.getDate() - 6)
      let start = now.format('yyyy-MM-dd')
      let filter = { groupId: 0, stationId: 0, type: 'box', start, end }
      let res = await this.$axios.post(`//${this.domains.trans}/station/analyse/EnergyMore`, filter)
      if (res.code !== 0) {
        return
      }
      this.energyLineData.x = []
      res.data.forEach(el => {
        el.val = (el.val * 1).toFixed(2)
        this.energyLineData.x.push(el.date)
        this.energyLineData.series[0].data.push(this.img.val(el.energy, 2))
        this.energyLineData.series[1].data.push(this.img.val(el.rate, 2))
        this.energyLineData.series[2].data.push(this.img.val(el.pr, 2))
        this.energyLineData.series[3].data.push(this.img.val(el.money, 2))
        this.energyLineData.series[4].data.push(this.img.val(el.save, 2))
      })
      this.timer2++
    },
    getWorkOrderCount: async function() {
      let res = await this.$axios.post(`//${this.domains.trans}/station/home/QueryWorkOrder`, {})
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return
      }
      //chartsPieData1
      this.workOrder = Object.assign(this.workOrder, res.data)
      this.chartsPieData1.series[0].value = res.data.types.upload //上报工单数量
      this.chartsPieData1.series[1].value = res.data.types.day //日常工单数量
      this.chartsPieData1.series[2].value = res.data.types.sys //系统工单数量
      this.chartsPieData1.series[0].name = this.showLang('com.order.escalation') //res.data.types.upload;
      this.chartsPieData1.series[1].name = this.showLang('com.order.daily') // res.data.types.day;
      this.chartsPieData1.series[2].name = this.showLang('com.order.system') //res.data.types.sys;
      //chartsPieData2
      this.chartsPieData2.series[0].value = res.data.sys.got
      this.chartsPieData2.series[1].value = res.data.sys.approve
      this.chartsPieData2.series[2].value = res.data.sys.level
      this.chartsPieData2.series[3].value = res.data.sys.deal
      this.chartsPieData2.series[0].name = this.showLang('com.order.received.alarm') // res.data.sys.got;
      this.chartsPieData2.series[1].name = this.showLang('com.order.confirmed') // res.data.sys.approve;
      this.chartsPieData2.series[2].name = this.showLang('com.order.fault.diagnosis') // res.data.sys.level;
      this.chartsPieData2.series[3].name = this.showLang('com.order.processed') // res.data.sys.deal;
      this.timer4++
      this.timer3++
    },
    lightingRate: async function() {
      let now = new Date()
      let end = now.format('yyyy-MM-dd')
      now.setDate(now.getDate() - 6)
      let start = now.format('yyyy-MM-dd')
      let res = await this.$axios.post(`//${this.domains.trans}/station/analyse/LightOnTrend`, {
        groupId: 0,
        stationId: 0,
        start: start,
        end: end
      })
      if (res.code !== 0) {
        return
      }
      this.lightingRateInfo = res.data
      this.chartsLineData.x = []
      this.chartsLineData.series[0].data = []
      ;(this.chartsLineData.yAxis = {
        type: 'value',
        min: 0,
        max: 100,
        interval: 20
      }),
        this.lightingRateInfo.forEach(el => {
          this.chartsLineData.x.push(el.time)
          this.chartsLineData.series[0].data.push(this.img.val(el.value, 2))
        })
      this.timer1++
    },
    asset: async function() {
    },
    equipment: function() {
      this.$axios.post(`//${this.domains.trans}/station/analyse/DeviceTotal`, { groupId: 0, stationId: 0 }).then(res => {
        this.$set(this, "equipmentInfo", res.data);
        this.$set(this, "assetInfo", {
          light: res.data.lamp.total,
          line: res.data.line,
          pole: res.data.pole.total,
          power: res.data.powerbox.total,
        });
      });
    },
    environment: async function() {
      let res = await this.$axios.post(`//${this.domains.trans}/station/home/QueryEnv`, {})
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return
      }
      this.environmentInfo = Object.assign(this.environmentInfo, res.data)
      let sun = new Date(new Date()).sunRiseAndSet(res.data.lat, res.data.lng)
      this.environmentInfo.sunRise = sun.rise
      this.environmentInfo.sunSet = sun.set
    },
    //获取天气
    getWeather: async function() {
      let result = await this.$axios.post(`//${this.domains.trans}/station/base/QueryWeather`, {})
      if (result.code == 0) {
        let city = result.data
        this.weather.degree = result.data.temperature //天气
        this.weather.type = city.weather
        weatherData.forEach(el => {
          el.have.forEach(ele => {
            if (ele == city.weather) {
              this.weather.url = el.url
            }
          })
        })
      } else {
        this.weather.degree = '----'
        this.weather.type = '----'
        this.weather.url = weatherData[0].url //默认
      }
    },
    refreshTime: function() {
      let now = new Date()
      this.nowTime.date = now.format('yyyy.MM.dd')
      this.nowTime.week = this.weeks[now.getDay()]
      this.nowTime.time = now.format('HH:mm:ss')
    }
  }
}
</script>
<style>
.left-top-rotation .ivu-carousel:hover .ivu-carousel-arrow-hover {
  opacity: 0.6;
}
.left-top-rotation button.ivu-carousel-arrow.right {
  right: 0px;
}
.left-top-rotation button.ivu-carousel-arrow.left {
  left: 0px;
}
.left-top-rotation i.ivu-icon-ios-arrow-forward {
  font-size: 22px;
}
.left-top-rotation i.ivu-icon-ios-arrow-back {
  font-size: 22px;
}
</style>

<style scoped>
.titlePublic {
  width: 100%;
  height: 28px;
}
.column {
  width: 3px;
  height: 28px;
  background-color: #00e3f0;
  margin-right: 7px;
  float: left;
}
.titleBox {
  width: calc(100% - 10px);
  height: 28px;
  float: right;
  background: linear-gradient(to right, #134794, #010e1c); /* 标准语法 */
  /* background: linear-gradient(90deg, rgba(19, 71, 148, 1) 0%, rgba(19, 71, 148, 1) 50%, rgba(19, 71, 148, 0.07046568627450978) 100%); */
}
.titleBox span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  margin-left: 15px;
}
.triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 7px solid #fad041;
  border-bottom: 7px solid transparent;
  margin-top: 7px;
  margin-left: 5px;
  opacity: 0.5;
}
.container {
  width: 100%;
  background-color: #010b13;
  display: flex;
  overflow: auto;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* position: relative; */
}
.container::-webkit-scrollbar {
  display: none;
}
.container-left {
  width: 550px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background: linear-gradient(90deg, rgba(5, 15, 36, 1) 0%, rgba(5, 15, 36, 0.35) 97%, rgba(5, 15, 36, 0.01) 100%);
  z-index: 50; */
}
.left-top {
  background-size: 100% 100%;
  height: 260px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 20px 0 20px;
	animation-duration: .8s;
}
.left-top-cloud {
  height: 90px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  margin-top: 10px;
}
.cloud-left {
  margin-left: 10px;
  padding-right: 10px;
  width: 130px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.cloud-left::after {
  content: '';
  width: 2px;
  height: 66px;
  border-right: 2px solid #83cbfd;
  position: absolute;
  left: 151px;
  opacity: 0.2;
}
.cloud-temp {
  margin-left: 10px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 52px;
}
.cloud-temp div {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #07a2fb;
}
.cloud-location {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #07a2fb;
  display: flex;
}
.cloud-location span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #07a2fb;
  margin: 0 10px;
}
.cloud-location div {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.cloud-left-img-cloud {
  width: 58px;
  height: 45px;
}
.cloud-left-img-location {
  width: 12px;
  height: 14px;
}
.cloud-content {
  width: 0;
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
}
.open-time {
  width: 170px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.open-time img {
  /* border: solid 1px red; */
  /* width: 15px;
  height: 19px; */
  margin: 0 8px;
}
.time-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0289f2;
  margin: 0 5px;
  word-break: keep-all;
}
.time-value {
  width: 36px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 0 5px 0 0px;
}
.left-top-status {
  height: 65px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 20px; */
}
.left-top-rotation {
  margin-top: 20px;
  background: linear-gradient(0deg, #002041, #012f59, #002041);
}
.rotation-title {
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 10px 0;
}
.rotation-title img {
  margin-right: 10px;
  vertical-align: middle;
}
.status-item {
  width: 115px;
  height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 6px 0 0 0;
}
.rotation-column {
  width: 1px;
  height: 36px;
  background-color: #011222ff;
}
.status-item-title {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* border: solid 1px red;*/
}
.status-item-value {
  font-size: 18px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #fad041;
  /* border: solid 1px red; */
}
.left-middle {
  background-size: 100% 100%;
  flex: none;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 0;
  height: calc(100% - 515px);
  min-height: 370px;
  justify-content: space-evenly;
  animation-duration: 1s;
}
.power-box-count {
  margin: 16px 0 0 10px;
  height: 25px;
  flex: none;
  /* border: solid 1px red; */
}
.power-box-count-desc {
  display: flex;
  align-items: center;
}
.power-box-count-desc img {
  margin: 0 10px 0 0;
}
.number-block {
  width: 20px;
  height: 26px;
  /* background: linear-gradient(0deg, #023eb3, #01152a);
  border-radius: 3px;
  border-image: linear-gradient(0deg, #036adc, #00162f) 1 1; */
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-right: 5px;
  line-height: 26px;
}
.power-box-count-desc span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0cd3fc;
  line-height: 18px;
  margin-right: 10px;
}
.power-box-detail {
  margin: 10px 0 0 5px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
}
.power-box-right {
  width: calc(100%);
  display: flex;
  justify-content: flex-start;
}
.chart-type-left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: calc(50% - 97px);
}
.chart-type-list {
  height: 23%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: right;
  color: #ffffff;
  line-height: 30px;
  margin: 5px 0;
}
.chart-type-list.con-right {
  text-align: left;
}
.chart-num {
  font-size: 16px;
  color: #028cf2;
}
.chart-type-color {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 2px;
}
.chart-type-chart {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border: 1px solid #09265c;
  border-radius: 50%;
  margin: 0 10px;
  box-sizing: border-box;
}
.chart-title {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 124px;
  margin-left: 27px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 130px;
  background-size: 116px 116px;
}
.chart-title img {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin: 20px auto 10px;
}
.chart-title .name {
  font-size: 16px;
  color: #a6b8d1;
}
.chart-title .num {
  font-size: 20px;
  color: #268bee;
}
.power-box-left {
  display: flex;
  flex-direction: initial;
  justify-content: space-around;
}
.power-box-left .power-box-item {
  height: 55px;
  display: flex;
  align-items: center;
  margin-right: 2px;
}
.power-box-item img {
  margin: 0 10px 0 10px;
}
.power-box-item div {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.power-detail-item {
  width: 226px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 2px;
}
.power-detail-item img {
  margin: 0 10px 0 30px;
}
.power-detail-item div {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.no-opacity {
  opacity: 1;
  font-weight: 400;
}
.grey {
  color: #aec0da;
  font-style: inherit;
  font-weight: 100;
}
.no-opacity .grey {
  margin-left: 3px;
}
.light-box-count {
  margin: 10px 0 0 10px;
  height: 25px;
  flex: none;
}
.light-box-detail {
  margin: 10px 0 0 10px;
  flex: auto;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
.left-bottom {
  background-size: 100% 100%;
  height: 255px;
  flex: none;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px;
  animation-duration: 1.2s;
}
.left-gauge-area {
  margin-top: 5px;
  height: 0;
  flex: auto;
  display: flex;
}
.container-center {
  width: 0;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 888px;
  /* border: solid 1px red; */
}
.center-top {
  background-size: 100% 100%;
  height: 80px;
  flex: none;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  /* padding-top: 10px;
  z-index: 50;
  background: linear-gradient(180deg, rgba(5, 15, 36, 1) 0%, rgba(5, 15, 36, 0.4354236694677871) 97%, rgba(5, 15, 36, 0.006039915966386533) 100%); */
}
.center-top-item {
  padding-top: 10px;
  /* width: 180px; */
  display: flex;
  align-items: center;
  /* border: solid 1px red; */
}
.center-top-item img {
  width: 78px;
  height: 82px;
  margin: 0 6px;
}
.center-top-item div {
  display: flex;
  flex-direction: column;
  margin-top: -30px;
}
.count-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c5def1;
  opacity: 0.7;
  margin-top: 13px;
}
.count-value {
  font-size: 18px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.center-middle {
  flex: auto;
  height: calc(100% - 345px);
}

.center-bottom {
  background-size: 100% 100%;
  height: 245px;
  flex: none;
  display: flex;
  flex-direction: column;
  padding: 8px 35px 10px;
  position: relative;
  animation-duration: 1s;
  /* z-index: 50;
  background: linear-gradient(360deg, rgba(5, 15, 36, 1) 0%, rgba(5, 15, 36, 0.4354236694677871) 95%, rgba(5, 15, 36, 0.006039915966386533) 100%); */
}
.energy-title {
  height: 40px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0cd3fc;
  position: absolute;
  right: 24px;
  top: 34px;
  z-index: 1000;
}
.title-img {
  margin: 0 10px;
  width: 14px;
  flex: none;
}
.title-name {
  /* margin: 0 10px; */
  flex: auto;
}
.title-menu {
  margin: 0 10px;
  /* align-self: flex-end; */
  display: flex;
  width: 245px;
  flex: none;
}
.title-menu-item {
  width: 61px;
  height: 22px;
  border: solid 1px #028af2;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68b7f0;
}
.title-menu-active {
  background: rgba(2, 138, 242, 0.5);
  color: #ffffff;
}
.energy-content {
  flex: auto;
  /* border: solid 1px red; */
}
.container-right {
  width: 540px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* z-index: 50;
  background: linear-gradient(270deg, rgba(5, 15, 36, 1) 0%, rgba(5, 15, 36, 0.4354236694677871) 97%, rgba(5, 15, 36, 0.006039915966386533) 100%); */
}
.right-top {
  background-size: 100% 100%;
  height: 300px;
  flex: none;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 6px 25px;
  animation-duration: 0.9s;
}
.chart-gauge-title {
  /* background: #00477E; */
  background: linear-gradient(90deg, #003863, #011628);
  opacity: 0.7;
  height: 30px;
  flex: none;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0px 0px 5px #0175d7;
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: relative;
}
.chart-gauge-title div {
  position: absolute;
  left: -10px;
  width: 2px;
  height: 22px;
  background: #028af2;
  margin-right: 8px;
  /* border: solid 1px red; */
}
.chart-gauge-title img {
  margin: 0 3px 0 8px;
  /* border: solid 1px red; */
}
.chart-gauge-area {
  height: 0;
  flex: auto;
  display: flex;
}
.work-chart {
  width: 49%;
  flex: auto;
}
.typeList {
  display: flex;
  justify-content: space-evenly;
}
.typeList .type {
  font-size: 14px;
  color: #aec0da;
  line-height: 18px;
}
.typeList .type i {
  display: inline-block;
  width: 8px;
  height: 5px;
  vertical-align: middle;
  margin-right: 4px;
}
.typeList .type .blue1 {
  background: #4177ff;
}
.typeRight {
  display: flex;
  justify-content: space-around;
  text-indent: 5px;
}
.typeRight .type {
  font-size: 14px;
  color: #aec0da;
  line-height: 18px;
  width: 75px;
}
.typeRight .type i {
  display: inline-block;
  width: 8px;
  height: 5px;
  vertical-align: middle;
  margin-right: 4px;
}
.sys1 {
  background: #3df3f5;
}
.sys2 {
  background: #fd7371;
}
.sys3 {
  background: #f8a754;
}
.sys4 {
  background: #009cff;
}
.typeList .type .blue2 {
  background: #0041fb;
}
.typeList .type .green {
  background: #04e0f9;
}
.right-bottom {
  background-size: 100% 100%;
  flex: none;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  padding: 0 25px 5px 25px;
  height: calc(100% - 310px);
  animation-duration: 1.1s;
}
.power-box-alarm {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.light-list-alarm {
  width: 100%;
  height: 280px;
  flex: none;
  display: flex;
  flex-direction: column;
}
.chart-nightingale {
  width: 100%;
  /* height: 100%; */
  flex: auto;
  /* border: solid 1px red; */
}
.light-list-title {
  /* border: solid 1px red; */
  margin-left: -3px;
  padding-top: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0cd3fc;
  height: 30px;
  flex: none;
  display: flex;
  align-items: center;
}
.light-list-title img {
  margin: 10px;
}
.power-box-alarm-list {
  height: 45px;
  margin-top: 15px;
  padding: 0 20px;
  flex: none;
  /* border: solid 1px red; */
  justify-content: space-around;
  display: flex;
}
.power-box-alarm-item {
  flex: auto;
  display: flex;
  /* padding-bottom: 20px; */
  /* border-bottom: solid 1px #0D3B75; */
  /* border: solid 1px red; */
}
.power-box-alarm-item img {
  margin: 0 10px;
}
.box-alarm-list-content {
  display: flex;
  flex-direction: column;
}
.box-alarm-list-content .list-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.6;
}
.box-alarm-list-content .list-value {
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.power-box-alarm-chart {
  /* border: solid 1px red; */
  padding: 20px 0 0 0;
  border-top: solid 1px #0d3b75;
  margin: 20px 5px 0 15px;
  flex: auto;
}
.es-screen-right-item{
  animation-name: slide1;
}
.es-screen-left-item {
	animation-name: slide2;
}
.es-screen-bottom-item {
	animation-name: slide3;
}
@keyframes slide1 {
  0% {
    transform: translateX(100%);
  }
  80% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide2 {
  0% {
    transform: translateX(-100%);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide3 {
  0% {
    transform: translateY(100%);
  }
  80% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
.es-screen-gis-item{
  animation: slideAndFade 1.5s;
}
@keyframes slideAndFade {
  0% {
    transform: translateY(200px);
		opacity: 0;
  }
  100% {
    transform: translateX(0);
		opacity: 1;
  }
}
</style>
